@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-color: black;
  background-image: url("../src/assets/Rectangle.png");
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-input-placeholder {
  padding-left: 6px;
}
input:focus {
  outline: none;
}
.micro {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background-color: "white"; /* Example background color */
  /* border-radius: 50%; */
  cursor: pointer;
  color: grey; /* Icon color */
}

.micro:hover {
  cursor: pointer;
}
video {
  border-radius: 200px;
  width: 46%;
}
/* Default styles for the video */
video {
  border-radius: 200px;
  width: 46%;
}
textarea:focus {
  outline: none;
}

/* Media query for screens smaller than a certain width (adjust the value as needed) */
@media screen and (max-width: 600px) {
  video {
    width: 100%;
  }
}

/* Responsive design for medium screens (adjust the max-width as needed) */
